<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      transactions: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchModel: "",
      searchMode: false,
    };
  },
  methods: {
    addBrowsers() {
      var data = {
        title: "popups.addUsers",
        inputs: [
          { model: "id", type: "text", label: "id" },

          { model: "user_id", type: "text", label: "user_id" },

          { model: "device", type: "text", label: "device" },

          { model: "name", type: "text", label: "name" },

          { model: "os_version", type: "text", label: "os_version" },

          { model: "os_name", type: "text", label: "os_name" },

          { model: "created", type: "text", label: "created" },

          { model: "updated", type: "text", label: "updated" },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("browsers", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      if (this.searchModel == "") {
        this.cancelappsearchMode();
      }
      this.searchMode = true;
      this.tot_pages = 0;
      this.http
        .post("transactions/search", { search: this.searchModel })
        .then((res) => {
          this.transactions = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.searchMode = false;
      this.get(this.page);
    },
    openDetails(id) {
      this.$router.push(`/journal_entries/${id}`);
    },
    get(page) {
      console.log(page);
      this.http
        .post("transactions/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.transactions = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('journal.title')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="background-color: #2a3042 !important"
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <router-link to="/add_journal">
                  <button type="button" class="btn btn-light float-end mb-4">
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </router-link>
              </div>
            </div>

            <!-- Start  table -->
            <table
              class="table table-centered table-nowrap table-striped align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("journal.date") }}</th>
                  <th scope="col">{{ $t("journal.id") }}</th>
                  <th scope="col">{{ $t("journal.tr_descr") }}</th>
                  <th scope="col">{{ $t("journal.amount") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactions"
                  :key="transaction"
                  class="active-transaction"
                  @click="openDetails(transaction?.id)"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ transaction?.created?.split("T")[0] }},
                    {{ transaction?.created?.split("T")[1]?.split(".")[0] }}
                  </td>
                  <td>#{{ transaction?.id }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? transaction?.descr
                          ? transaction?.descr
                          : transaction?.descr
                        : transaction?.descr_en
                        ? transaction?.descr_en
                        : transaction?.descr
                    }}
                  </td>
                  <td>
                    {{ parseFloat(transaction?.amount).toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- end  table -->

            <!--  Start Apps  Pagination     -->
            <ul
              class="pagination pagination-rounded justify-content-center mb-2"
            >
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!-- End  Apps  Pagination     -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.active-transaction:hover {
  cursor: pointer;
  background: rgb(222, 222, 226);
}
.active-transaction {
  padding: 1rem !important;
}
td,
th {
  padding: 1rem;
}
</style>
